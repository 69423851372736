import { createAsyncThunk } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { makeObject } from "shared/js/helpers";

export const getMyReports = createAsyncThunk("posts/getMyReports", async ({ token, id }) => {
  const res = await request({ endpoint: endpoint.reports, params: { per_page: 1000, author: id }, token });
  return res;
});

export const getMyReportsReducers = {
  [getMyReports.pending]: (state) => {
    state.isLoading.reports = true;
  },
  [getMyReports.fulfilled]: (state, { payload }) => {
    const formatedReports = makeObject(payload);
    if (formatedReports?.length > 0) {
      state.myreports = [];
      state.myreports = [...state.myreports, ...formatedReports];
      state.page.myreports = state.page.myreports + 1;
    } else {
      state.notifications = [...state.notifications, { id: nanoid(), text: "No personal reports to load" }];
    }
    state.isLoading.reports = false;
  },
  [getMyReports.rejected]: (state) => {
    state.isLoading.reports = false;
  },
};
