import axios from "axios";
import { WP_REST_URL, WP_AUTH_URL } from "./constants";
import { endpoint } from "./endpoints";

export const getJWT = async ({ username, password }) => {
  try {
    const user = await axios.post(WP_AUTH_URL, { username, password });
    return user.data;
  } catch (err) {
    return err;
  }
};

const handleSuccessResponse = (response) => {
  const { data } = response || {};
  const { response: nestedResponse } = data || {};
  return nestedResponse || data;
};

const handleErrorResponse = (error) => {
  const { response } = error || {};
  const { data } = response || {};

  if (!data) {
    localStorage.clear();
  }

  return data;
};

export const request = async ({
  method = "get",
  base = WP_REST_URL,
  endpoint = "",
  headers = {},
  data,
  params,
  token,
  ...restProps
}) => {
  const url = `${base}/${endpoint}`;
  const defaultHeaders = { "Content-Type": "application/json" };

  if (token) {
    defaultHeaders["Authorization"] = `Bearer ${token}`;
  }

  return axios({ url, method, data, headers: { ...defaultHeaders, ...headers }, params, ...restProps })
    .then(handleSuccessResponse)
    .catch(handleErrorResponse);
};

export const sendEmail = async ({ params, token }) => {
  const res = await request({ endpoint: `${endpoint.mailapi}`, params, token });
  return res;
};

export const resetPassword = async ({ params }) => {
  const res = await request({ endpoint: `${endpoint.mailapi}`, params });
  return res;
};
