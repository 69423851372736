import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { postToList, sortDropdownList } from "shared/js/helpers";

export const getAllVenues = createAsyncThunk("posts/getAllVenues", async ({ token }) => {
  let res = [];
  let page = 1;

  let currentRes = await request({ endpoint: endpoint.venues, params: { per_page: 1000, page }, token });
  res.push(...currentRes);
  page++;

  while (currentRes.length === 1000) {
    currentRes = await request({ endpoint: endpoint.venues, params: { per_page: 1000, page }, token });
    res.push(...currentRes);
    page++;
  }

  return res;
});

export const getAllVenuesReducers = {
  [getAllVenues.pending]: (state) => {
    state.isLoading.venues = true;
  },
  [getAllVenues.fulfilled]: (state, { payload }) => {
    const newList = postToList(payload);
    const sortList = sortDropdownList(newList);
    state.venues.list = sortList;
    state.venues.lastUpdate = new Date().getTime();
    state.isLoading.venues = false;
  },
  [getAllVenues.rejected]: (state) => {
    state.isLoading.venues = false;
  },
};
