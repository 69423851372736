import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";

export const getMembersList = createAsyncThunk("site/getMembersList", async ({ token }) => {
  let res = [];
  let page = 1;

  let currentRes = await request({ endpoint: endpoint.users, token, params: { per_page: 1000, page } });
  res.push(...currentRes);
  page = page + 1;

  while (currentRes.length === 1000) {
    currentRes = await request({ endpoint: endpoint.users, token, params: { per_page: 1000, page } });
    res.push(...currentRes);
    page = page + 1;
  }

  return res;
});

export const getMembersListReducers = {
  [getMembersList.pending]: (state) => {
    state.isLoading.members = true;
  },
  [getMembersList.fulfilled]: (state, { payload }) => {
    state.members = payload;
    state.isLoading.members = false;
  },
  [getMembersList.rejected]: (state) => {
    state.isLoading.members = false;
  },
};
