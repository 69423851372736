import React, { memo } from "react";
import { icons } from "./icons";

const Icon = ({ name, className, ...otherProps }) => {
  if (icons.has(name)) {
    return React.cloneElement(icons.get(name), { className, ...otherProps });
  }

  return <span>icon404</span>;
};

export default memo(Icon);
