import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { postToList, sortDropdownList } from "shared/js/helpers";

export const getClubsList = createAsyncThunk("posts/getClubsList", async ({ token }) => {
  let res = [];
  let page = 1;

  let currentRes = await request({ endpoint: endpoint.teams, params: { per_page: 1000, page }, token });
  res.push(...currentRes);
  page++;

  while (currentRes.length === 1000) {
    currentRes = await request({ endpoint: endpoint.teams, params: { per_page: 1000, page }, token });
    res.push(...currentRes);
    page++;
  }

  return res;
});

export const getClubsListReducers = {
  [getClubsList.pending]: (state) => {
    state.isLoading.teams = true;
  },
  [getClubsList.fulfilled]: (state, { payload }) => {
    const newList = postToList(payload);
    const sortList = sortDropdownList(newList);
    state.teams.list = sortList;
    state.teams.lastUpdate = new Date().getTime();
    state.isLoading.teams = false;
  },
  [getClubsList.rejected]: (state) => {
    state.isLoading.teams = false;
  },
};
