import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";

export const getGroups = createAsyncThunk("posts/getGroups", async ({ token }) => {
  const res = await request({ endpoint: endpoint.groups, token });
  return res;
});

export const getGroupsReducers = {
  [getGroups.pending]: (state) => {
    state.isLoading.groups = true;
  },
  [getGroups.fulfilled]: (state, { payload }) => {
    state.groups = payload;
    state.isLoading.groups = false;
  },
  [getGroups.rejected]: (state) => {
    state.isLoading.groups = false;
  },
};
