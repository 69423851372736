export const ROUTES = Object.freeze({
  login: "/",
  dashboard: "/dashboard",
  reports: "/reports",
  analytics: "/analytics",
  filters: "/filters",
  members: "/members",
  member: "/member",
  settings: "/settings",
  profile: "/profile",
  reset: "/reset-password",
});
