import { createAsyncThunk } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { makeObject } from "shared/js/helpers";

export const getAllReports = createAsyncThunk("posts/getAllReports", async ({ token, page }) => {
  const res = await request({ endpoint: endpoint.reports, params: { per_page: 1000, page }, token });
  return res;
});

export const getAllReportsReducers = {
  [getAllReports.pending]: (state) => {
    state.isLoading.reports = true;
  },
  [getAllReports.fulfilled]: (state, { payload }) => {
    const formatedReports = makeObject(payload);
    if (formatedReports?.length > 0) {
      state.reports = [];
      state.reports = [...state.reports, ...formatedReports];
      state.page.reports = state.page.reports + 1;
    } else {
      state.notifications = [...state.notifications, { id: nanoid(), text: "No more reports to load" }];
    }
    state.isLoading.reports = false;
  },
  [getAllReports.rejected]: (state) => {
    state.isLoading.reports = false;
  },
};
