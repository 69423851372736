import { createAsyncThunk } from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { makeObject } from "shared/js/helpers";

export const getAllReportsByDate = createAsyncThunk("posts/getAllReportsByDate", async ({ token, date }) => {
  let dateByValue = new Date(date);
  dateByValue.setFullYear(dateByValue.getFullYear() + 1);

  const res = await request({
    endpoint: endpoint.reports,
    params: { per_page: 5000, after: date, before: dateByValue.toISOString() },
    token,
  });
  return res;
});

export const getAllReportsByDateReducers = {
  [getAllReportsByDate.pending]: (state) => {
    state.isLoading.reports = true;
  },
  [getAllReportsByDate.fulfilled]: (state, { payload }) => {
    const formatedReports = makeObject(payload);

    if (formatedReports?.length > 0) {
      state.reports = [...state.reports, ...formatedReports];
    } else {
      state.notifications = [...state.notifications, { id: nanoid(), text: "No more reports to load" }];
    }

    state.isLoading.reports = false;
  },
  [getAllReportsByDate.rejected]: (state) => {
    state.isLoading.reports = false;
  },
};
