import React from "react";

const LoadingPage = () => (
  <div className="loading">
    <img src="/img/logo_icon.png" alt="loading icon" title="reporting tool loading" />
    <h3>Loading...</h3>
  </div>
);

export default LoadingPage;
