import { createAsyncThunk } from "@reduxjs/toolkit";
import { getJWT } from "shared/js/api";

// GET USER TOKEN
export const getToken = createAsyncThunk("site/getToken", async (user) => getJWT(user));

export const getTokenReducers = {
  [getToken.pending]: (state) => {
    state.isLoading.user = true;
  },
  [getToken.fulfilled]: (state, { payload }) => {
    state.isLoading.user = false;
    state.user.token = payload.token;
    state.user.email = payload.user_email;
    state.user.displayName = payload.user_display_name;
  },
  [getToken.rejected]: (state) => {
    state.isLoading.user = false;
  },
};
