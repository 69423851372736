import { isEmpty } from "lodash";

export const getFormatedDate = (date) => {
  const d = new Date(date) || new Date();
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};

export const getAvatar = (avatarUrls = []) => {
  if (isEmpty(avatarUrls)) return null;
  const [avatar] = Object.values(avatarUrls);
  return avatar;
};

export const getUserRoleFromArray = (roles = "") => {
  switch (true) {
    case roles.includes("administrator"):
      return "admin";
    case roles.includes("full_membership"):
      return "full membership";
    case roles.includes("associate_membership"):
      return "associate membership";
    case roles.includes("affiliate_membership"):
      return "affiliate membership";
    case roles.includes("corporate_membership"):
      return "corporate membership";
    default:
      return "member";
  }
};

export const clearString = (string) => {
  if (!string?.length) return "";

  return String(string)
    .replaceAll("<p>", "")
    .replaceAll("</p>", "")
    .replaceAll("&#8220;", '"')
    .replaceAll("&#8221;", '"')
    .replaceAll("&#8243;", '"')
    .replaceAll('\\"', "")
    .replaceAll("&#8211;", "-")
    .replaceAll("&#8216;", "'")
    .replaceAll("&#038;", "&")
    .replaceAll("&#8217;", "'");
};

export const makeObject = (data) => {
  if (isEmpty(data) || data?.data?.status > 200) return [];

  return data?.map((post) => {
    const postStringCleared = clearString(post.content.rendered);
    const newContent = postStringCleared ? JSON.parse(postStringCleared) : {};

    return {
      content: newContent,
      id: post?.id,
      modified: post?.modified,
      title: clearString(post.title.rendered),
      type: post?.type,
      slug: post?.slug,
      groups: post?.groups,
    };
  });
};

export const postToList = (payload, isOrderLocked = false) => {
  if (isEmpty(payload)) return [];

  if (isOrderLocked) {
    const parsed = makeObject(payload);
    const newList = parsed.map((post) => ({ label: post.title, value: post.id, order: post.content.order }));
    return newList;
  }

  const list = payload.map((post) => ({ label: clearString(post.title.rendered), value: post.id }));
  return list;
};

export const reportToCategory = (report) => {
  if (isEmpty(report)) return {};

  return Object.entries(report).reduce((ac, cv) => {
    const [key, value] = cv;

    ac[value.category] = {
      ...ac?.[value.category],
      [key]: value.value,
    };

    return ac;
  }, {});
};

export const reportToEdit = (report) => {
  if (!report) return {};

  return Object.entries(report).reduce((ac, cv) => {
    const [key, item] = cv;
    ac[key] = item.value;
    return ac;
  }, {});
};

export const addZero = (string) => {
  return String(string)?.length < 2 ? `0${string}` : string;
};

export const scrollIntoView = () => {
  document.body.scrollIntoView({ behavior: "smooth" });
  return;
};

export const updateTimer = (date) => {
  const future = Date.parse(date);
  const now = new Date();
  const diff = future - now;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const mins = Math.floor(diff / (1000 * 60));
  const secs = Math.floor(diff / 1000);

  const d = days;
  const h = hours - days * 24;
  const m = mins - hours * 60;
  const s = secs - mins * 60;

  return {
    d: d > 0 ? d : 0,
    h: h > 0 ? h : 0,
    m: m > 0 ? m : 0,
    s: s > 0 ? s : 0,
  };
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getPermissions = (user) => {
  const { role, membershipStatus } = user;
  if (!membershipStatus) return membershipStatus;
  return role !== "member";
};

export const translateGroupToId = (availableGroups, groupToGet) => {
  if (!availableGroups?.length) return false;
  const [group] = availableGroups?.filter((group) => group?.slug === groupToGet);
  return group?.id || 0;
};

export const translateIdToGroup = (availableGroups, idToGet) => {
  if (!availableGroups?.length) return "";
  const [group] = availableGroups?.filter((group) => group?.id === idToGet);
  return group?.slug || "";
};

export const idToGroupToLabel = (availableGroups, idToGet) => {
  if (!availableGroups?.length) return "";
  const [id] = idToGet;
  const group = translateIdToGroup(availableGroups, id);

  switch (group) {
    case "mens":
      return "Men's Football";
    case "womens":
      return "Women's Football";
    case "concert":
      return "Concert";
    default:
      return "Other";
  }
};

export const capitalizeWord = (word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

export const getCompetitionsInSeason = ({ id, leagues }) => {
  if (!id) return [];

  const availableLeagues = leagues.filter((league) => league.content.season === id);
  const leaguesList = availableLeagues.map((league) => ({
    label: league.title,
    value: league.id,
    order: league.content.order,
  }));
  return leaguesList || [];
};

export const getTeamsListFromCompetition = ({ id, leagues }) => {
  if (!id) return [];

  const availableTeams = leagues.find((league) => league.id === id);
  return availableTeams.content.leagueTeams || [];
};

export const getReportViewData = (config) => {
  const { teams, leagues, seasons, agencies, venues } = config;
  const allLabels = [teams.list, leagues.list, seasons.list, agencies.list, venues.list].flat();

  return allLabels.reduce((ac, cv) => {
    ac[cv.value] = cv.label;
    return ac;
  }, {});
};

export const getAttachedLabel = (id, list) => {
  if (!id) return "";
  return list?.find((item) => item?.value === id)?.label;
};

export const isLoadingSite = (isLoading) => {
  if (!isLoading) return false;
  return !Object.values(isLoading).every((value) => value === false);
};

export const pairList = (a, b) => {
  if (!a || !b) return [];
  return a.filter((item) => b.includes(item.value));
};

export const stringify = (string) => String(string).toLowerCase().replaceAll(" ", "");

export const sortDropdownList = (list) => {
  return list.sort((a, b) => {
    const nameA = a.label.toLowerCase();
    const nameB = b.label.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

export const sortCompetitionList = (list) => {
  return list.sort((a, b) => {
    const nameA = a.order;
    const nameB = b.order;

    if (nameA < nameB) {
      return -1;
    }

    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
};

export const buildCSVObject = (list) => {
  if (!list) return [];

  const userData = list
    .map((user) => ({ name: user?.name, email: user?.email }))
    .map((user) => Object.values(user))
    .map((data) => data.join(",").concat(",\n"))
    .join("");

  const csvContent = `data:text/csv;charset=utf-8,Name, Email,\n${userData}`;
  const encodedUri = encodeURI(csvContent);

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `users.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  return;
};

export const sortReportsByDate = (reports) => {
  if (!reports) return [];

  const arr = [...reports].sort((a, b) => {
    const dateA = new Date(a?.content?.koDate?.value);
    const dateB = new Date(b?.content?.koDate?.value);
    return dateA - dateB;
  });

  return arr;
};

export const getDateYearBySeason = (season) => {
  let date = new Date();
  if (!season) return date;

  const seasonYear = Number(season.label.split("/")[0]);
  date.setFullYear(seasonYear, 0, 1);
  date.setMilliseconds(0);
  date.setSeconds(0);
  date.setMinutes(0);
  date.setHours(0);

  return date.toISOString();
};

export const getMembershipDate = (date) => {
  if (!date) return new Date();

  const [year, month, day] = String(date).split("-");
  let d = new Date();
  d.setFullYear(year, month - 1, day);
  return d.getTime();
};
