import React from "react";
import { createRoot } from "react-dom/client";

import AppStore from "containers/app-store";
import AppRouter from "containers/app-route";

import reportWebVitals from "./reportWebVitals";
import NotificationCard from "components/site/notification-card";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AppStore>
      <NotificationCard />
      <AppRouter />
    </AppStore>
  </React.StrictMode>
);

reportWebVitals();
