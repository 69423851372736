import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { postToList, sortDropdownList } from "shared/js/helpers";

export const getAllAgencies = createAsyncThunk("posts/getAllAgencies", async ({ token }) => {
  let res = [];
  let page = 1;

  let currentRes = await request({ endpoint: endpoint.agencies, params: { per_page: 1000, page }, token });
  res.push(...currentRes);
  page++;

  while (currentRes.length === 1000) {
    currentRes = await request({ endpoint: endpoint.agencies, params: { per_page: 1000, page }, token });
    res.push(...currentRes);
    page++;
  }

  return res;
});

export const getAllAgenciesReducers = {
  [getAllAgencies.pending]: (state) => {
    state.isLoading.agencies = true;
  },
  [getAllAgencies.fulfilled]: (state, { payload }) => {
    const newList = postToList(payload);
    const sortList = sortDropdownList(newList);
    state.agencies.list = sortList;
    state.agencies.lastUpdate = new Date().getTime();
    state.isLoading.agencies = false;
  },
  [getAllAgencies.rejected]: (state) => {
    state.isLoading.agencies = false;
  },
};
