import { createSlice } from "@reduxjs/toolkit";
import {
  getMembersListReducers,
  getTokenReducers,
  getUserProfileReducers,
  updateUserProfileReducers,
  getReportsReducers,
  getGroupsReducers,
  getAllReportsReducers,
  getAllAgenciesReducers,
  getAllFootballCompetitionsReducers,
  getAllSeasonsReducers,
  getClubsListReducers,
  getAllVenuesReducers,
  getAllClubsReducers,
  getMyReportsReducers,
  getAllReportsByDateReducers,
} from "./reducers";

const initialState = {
  // site
  sidebarToggle: false,
  isLoading: {
    site: false,
    user: false,
    members: false,
    groups: false,
    reports: false,
    agencies: false,
    leagues: false,
    seasons: false,
    venues: false,
    teams: false,
  },
  page: {
    reports: 1,
    myreports: 1,
  },
  notifications: [],

  // user
  user: {
    id: null,
    username: null,
    email: null,
    avatar: null,
    displayName: null,
    token: null,
    role: null,
    club: "",
    location: "",
    phone: "",
    jobTitle: "",
    venue: "",
    membershipStatus: "",
  },
  members: [],
  selectedMember: {},

  // reports
  currentPost: null,
  reports: [],
  myreports: [],
  groups: [],

  // config
  leagues: { list: [], value: [], lastUpdate: null },
  teams: { list: [], lastUpdate: null },
  seasons: { list: [], lastUpdate: null },
  agencies: { list: [], lastUpdate: null },
  venues: { list: [], lastUpdate: null },
};

const reducers = {
  // site
  toggleSidebar(state, { payload }) {
    state.sidebarToggle = payload;
  },
  setLoading(state, { payload }) {
    state.isLoading = {
      ...state.isLoading,
      ...payload,
    };
    state.sidebarToggle = false;
  },
  setUsername(state, { payload }) {
    state.user.username = payload;
  },
  addNotifications(state, { payload }) {
    state.notifications = [...state.notifications, payload];
  },
  removeNotifications(state, { payload }) {
    state.notifications = state.notifications.filter((notification) => notification.id !== payload);
  },
  setSelectedMember(state, { payload }) {
    state.selectedMember = payload;
  },

  // reports
  setCurrentPost(state, { payload }) {
    state.currentPost = payload;
  },

  // config
  addNewTeam(state, { payload }) {
    state.teams.list = [payload, ...state.teams.list];
  },
  addNewAgency(state, { payload }) {
    state.agencies.list = [payload, ...state.agencies.list];
  },
  addNewSeason(state, { payload }) {
    state.seasons.list = [payload, ...state.seasons.list];
  },
  addNewVenue(state, { payload }) {
    state.venues.list = [payload, ...state.venues.list];
  },
};

const extraReducers = {
  // site
  ...getTokenReducers,
  ...getUserProfileReducers,
  ...updateUserProfileReducers,
  ...getMembersListReducers,

  // reports
  ...getReportsReducers,
  ...getGroupsReducers,
  ...getAllReportsReducers,
  ...getMyReportsReducers,
  ...getAllReportsByDateReducers,

  // config
  ...getAllAgenciesReducers,
  ...getAllFootballCompetitionsReducers,
  ...getAllSeasonsReducers,
  ...getClubsListReducers,
  ...getAllVenuesReducers,
  ...getAllClubsReducers,
};

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers,
  extraReducers,
});

export * from "./reducers";
export const {
  toggleSidebar,
  setLoading,
  setUsername,
  addNotifications,
  removeNotifications,
  setSelectedMember,
  setCurrentPost,
  addNewTeam,
  addNewAgency,
  addNewSeason,
  addNewVenue,
} = mainSlice.actions;
export const main = mainSlice.reducer;
