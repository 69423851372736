import React from "react";

// Component Icon List
import { ReactComponent as Rotation3DIcon } from "./svgs/3d_rotation.svg";
import { ReactComponent as AddTaskIcon } from "./svgs/add_task.svg";
import { ReactComponent as CloseFullScreenIcon } from "./svgs/close_fullscreen.svg";
import { ReactComponent as AssignmentIcon } from "./svgs/assignment.svg";
import { ReactComponent as CloseIcon } from "./svgs/close.svg";
import { ReactComponent as MenuIcon } from "./svgs/menu.svg";
import { ReactComponent as LoadingIcon } from "./svgs/loading.svg";
import { ReactComponent as DashboardIcon } from "./svgs/dashboard.svg";
import { ReactComponent as TimelineIcon } from "./svgs/timeline.svg";
import { ReactComponent as FilterIcon } from "./svgs/filter.svg";
import { ReactComponent as UsersIcon } from "./svgs/users.svg";
import { ReactComponent as SettingsIcon } from "./svgs/settings.svg";
import { ReactComponent as ProfileIcon } from "./svgs/profile.svg";
import { ReactComponent as ArrowRightIcon } from "./svgs/arrow_right.svg";
import { ReactComponent as MailIcon } from "./svgs/mail.svg";
import { ReactComponent as PersonAddIcon } from "./svgs/person_add.svg";
import { ReactComponent as CalendarIcon } from "./svgs/backup_table.svg";
import { ReactComponent as FootballIcon } from "./svgs/sports_soccer.svg";
import { ReactComponent as InfoIcon } from "./svgs/error_outline.svg";
import { ReactComponent as AddIcon } from "./svgs/add.svg";
import { ReactComponent as DeleteIcon } from "./svgs/delete_sweep.svg";

// Exported Icon List Map
export const icons = new Map([
  ["rotation3d", <Rotation3DIcon />],
  ["addTask", <AddTaskIcon />],
  ["closeFullScreen", <CloseFullScreenIcon />],
  ["assignment", <AssignmentIcon />],
  ["close", <CloseIcon />],
  ["menu", <MenuIcon />],
  ["loading", <LoadingIcon />],
  ["dashboard", <DashboardIcon />],
  ["timeline", <TimelineIcon />],
  ["filter", <FilterIcon />],
  ["users", <UsersIcon />],
  ["settings", <SettingsIcon />],
  ["profile", <ProfileIcon />],
  ["arrowRight", <ArrowRightIcon />],
  ["mail", <MailIcon />],
  ["personAdd", <PersonAddIcon />],
  ["calendar", <CalendarIcon />],
  ["football", <FootballIcon />],
  ["info", <InfoIcon />],
  ["add", <AddIcon />],
  ["delete", <DeleteIcon />],
]);
