import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { postToList } from "shared/js/helpers";

// ASYNC GET SEASONS LISTS
export const getAllSeasons = createAsyncThunk("posts/getAllSeasons", async ({ token }) => {
  let res = [];
  let page = 1;

  let currentRes = await request({ endpoint: endpoint.seasons, params: { per_page: 1000, page }, token });
  res.push(...currentRes);
  page++;

  while (currentRes.length === 1000) {
    currentRes = await request({ endpoint: endpoint.seasons, params: { per_page: 1000, page }, token });
    res.push(...currentRes);
    page++;
  }

  return res;
});

export const getAllSeasonsReducers = {
  [getAllSeasons.pending]: (state) => {
    state.isLoading.seasons = true;
  },
  [getAllSeasons.fulfilled]: (state, { payload }) => {
    const newList = postToList(payload);
    state.seasons.list = newList;
    state.seasons.lastUpdate = new Date().getTime();
    state.isLoading.seasons = false;
  },
  [getAllSeasons.rejected]: (state) => {
    state.isLoading.seasons = false;
  },
};
