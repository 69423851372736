export const endpoint = Object.freeze({
  users: "users",
  profile: "users/me",
  reports: "reports",
  club: "club",
  groups: "groups",
  agencies: "agencylists",
  leagues: "leagueslists",
  seasons: "seasons",
  teams: "teamslists",
  mailapi: "email",
  venues: "venues",
});
