import { ROUTES } from "containers/app-route/routes";
const { dashboard, reports, filters, members, settings, profile } = ROUTES;

export const WP_BASE = "https://fsoa.org.uk";
export const WP_REST_URL = WP_BASE + "/wp-json/wp/v2";
export const WP_AUTH_URL = WP_BASE + "/wp-json/jwt-auth/v1/token";
export const WP_JABS_LIBRARY = WP_BASE + "/wp-json/jabs-library/v1";

export const PERMISSION_LIST = Object.freeze({
  admin: [dashboard, profile, reports, filters, members, settings],
  "full membership": [dashboard, profile, reports, filters, members],
  "associate membership": [dashboard, profile, reports, filters, members],
  "affiliate membership": [dashboard],
  "corporate membership": [dashboard],
  member: [dashboard],
});

export const YES_NO_OPTIONS = Object.freeze([
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
]);

export const FILTER_GROUPS_LABELS = {
  mens: "Mens Football",
  womens: "Womens Football",
  concert: "Concerts",
  other: "Other Sports",
};

export const FORM_CATEGORY = Object.freeze({
  teams: "Teams",
  security: "Security",
  preMatch: "Pre-match",
  preEvent: "Pre-event",
  stadium: "Stadium",
  event: "Event",
  concertDetails: "Concert details",
  location: "Location",
  transport: "Transport",
  other: "Other",
  postMatch: "Post Match",
  postEvent: "Post Event",
  agency: "Agency(s)",
});

export const LABEL_KEY_FORMS = Object.freeze({
  competitionType: "Competition",
  gameSeason: "Season",
  homeTeam: "Home team",
  awayTeam: "Away team",
  homeResult: "Home result",
  awayResult: "Away result",
  koDate: "Kick-off date",
  koTime: "Kick-off time",
  preMatchInfo: "Pre-match info",
  preMatchCommunication: "Pre-match communication",
  policeCategory: "Police category",
  clubCategory: "Club cateogry",
  clubStewardsNonSia: "Club stewards (non-SIA)",
  visitingStewards: "Visiting Club stewards",
  visitingClubStewards: "Visiting Club stewards",
  clubStewards: "Club stewards (SIA)",
  agencyName: "Agency name(s)",
  otherAgencyName: "Specify Other",
  isDogAgency: "Dog agency",
  nameDogAgency: "Name(s) of dog agency",
  trafficManagementPlan: "Traffic management plan",
  isHvm: "HVM",
  isTrafficRegulationOrder: "Traffic Regulation Order",
  isFanzoneOperation: "Is there a fanzone in operation?",
  isVisitorsAllowedFanzone: "Are visitors allowed in the fanzone?",
  isClubBarsOpen: "Are any club bars open to visitors?",
  ticketSales: "Ticket sales",
  awayTicketSales: "Away ticket sales",
  attendance: "Attendance",
  homeAttendance: "Home attendance",
  awayAttendance: "Away attendance",
  homeEntryRefusals: "Home Entry Refusals",
  awayEntryRefusals: "Away Entry Refusals",
  homeEntryRefusalsReasons: "Reasons",
  awayEntryRefusalsReasons: "Reasons",
  homeEjections: "Home Ejections",
  awayEjections: "Away Ejections",
  homeEjectionsReasons: "Reasons",
  awayEjectionsReasons: "Reasons",
  homeArrests: "Home Arrests",
  awayArrests: "Away Arrests",
  arrests: "Arrests",
  ejections: "Ejections",
  homeArrestsReasons: "Reasons",
  awayArrestsReasons: "Reasons",
  isSafeguardingIssues: "Any safeguarding issues?",
  safeguardingIssuesDetails: "Details",
  isVisitorDepartureIssues: "Any visitor departure issues?",
  visitorDepartureIssuesDetails: "Details",
  coaches: "Coaches",
  minibuses: "Minibuses",
  weather: "Weather",
  medicalReport: "Medical report",
  numberOfIncidents: "Total Number of incidents",
  numberOfIncidentsSpec: "Total number of incidents - spectators",
  preExistingConditionsIncidentsSpec: "Incidents due to pre-existing conditions - spectators",
  numberOfIncidentsStaff: "Total number of incidents - staff",
  preExistingConditionsIncidentsStaff: "Incidents due to pre-existing conditions - staff",
  preExistingConditions: "Incidents due to pre-existing conditions",
  name: "Name",
  position: "Position",
  contactDetails: "Contact Details",
  otherComments: "Other comments",
  isSegregated: "Segregated / Unsegregated",
  venue: "Venue",
  artist: "Artist",
  date: "Date",
  time: "Time",
  preEventCommunication: "Pre-event communication",
  preEventInfo: "Pre-event info",
  year: "Year",
  licensing: "Licensing",
  actsIssues: "Acts issues",
  profileActs: "Acts profile",
  eventPromoter: "Event promoter",
  profileAudience: "Audience profile",
  audienceIssues: "Audience issues",
  postEventComments: "Post-event comments",
  owner: "By Club",
  agencyStewardsSIA: "Agency Stewards (SIA)",
  agencyStewards: "Agency Stewards (NON-SIA)",
});

export const MEMBERSHIP_LIST_ACCOUNT_CREATION = Object.freeze([
  { label: "Admin", value: "administrator" },
  { label: "Full Membership", value: "full_membership" },
  { label: "Associate Membership", value: "associate_membership" },
]);

export const REGION_ACCOUNT_CREATION = Object.freeze([
  { label: "North West", value: "North West" },
  { label: "North East", value: "North East" },
  { label: "West Midlands", value: "West Midlands" },
  { label: "East Midlands", value: "East Midlands" },
  { label: "South West", value: "South West" },
  { label: "South East", value: "South East" },
]);

export const GAME_SECURITY_LIST = Object.freeze([
  { label: "High", value: "High" },
  { label: "Medium", value: "Medium" },
  { label: "Low", value: "Low" },
  { label: "Club Security", value: "Club Security" },
  { label: "Police Free", value: "Police Free" },
  { label: "Police Free +", value: "Police Free Plus" },
  { label: "Police Spotters Only", value: "Police Spotters Only" },
]);

export const GAME_AGENCY_LIST = Object.freeze([
  { label: "None", value: "" },
  { label: "Other", value: "Other" },
]);

export const FILTER_TYPES_LIST = Object.freeze([
  { label: "All", value: "All" },
  { label: "Mens Football", value: "Mens Football" },
  { label: "Womens Football", value: "Womens Football" },
  { label: "Concerts", value: "Concerts" },
]);
