import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { getAvatar } from "shared/js/helpers";

// UPDATE USER PROFILE
export const updateUserProfile = createAsyncThunk("site/updateUserProfile", async ({ token, id, params }) => {
  const res = await request({ method: "post", endpoint: `${endpoint.users}/${id}`, token, params });
  return res;
});

export const updateUserProfileReducers = {
  [updateUserProfile.pending]: (state) => {
    state.isLoading.user = true;
  },
  [updateUserProfile.fulfilled]: (state, { payload }) => {
    state.user.avatar = getAvatar(payload.avatar_urls);
    state.user.displayName = payload.name;
    state.user.username = payload.slug;
    state.user.club = payload.meta.club;
    state.user.membershipStatus = payload.meta.membershipStatus;
    state.user.location = payload.meta.userLocation;
    state.user.jobTitle = payload.meta.userJobTitle;
    state.user.venue = payload.meta.userMainVenue;
    state.user.phone = payload.meta.userPhone;
    state.user.userNotes = payload.meta.userNotes;
    state.user.email = payload.email;
    state.isLoading.user = false;
  },
  [updateUserProfile.rejected]: (state) => {
    state.isLoading.user = false;
    localStorage.clear();
  },
};
