import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { makeObject } from "shared/js/helpers";

export const getReports = createAsyncThunk("posts/getReports", async ({ token }) => {
  const res = await request({ endpoint: endpoint.reports, params: { per_page: 200 }, token });
  return res;
});

export const getReportsReducers = {
  [getReports.pending]: (state) => {
    state.isLoading.reports = true;
  },
  [getReports.fulfilled]: (state, { payload }) => {
    const formatedResults = makeObject(payload);
    state.reports = formatedResults;
    state.page.reports = 1;
    state.isLoading.reports = false;
  },
  [getReports.rejected]: (state) => {
    state.isLoading.reports = false;
  },
};
