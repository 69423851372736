import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";
import { getAvatar, getUserRoleFromArray } from "shared/js/helpers";

// GET USER PROFILE
export const getUserProfile = createAsyncThunk("site/getUserProfile", async ({ token }) => {
  const res = await request({ endpoint: endpoint.profile, token });
  return res;
});

export const getUserProfileReducers = {
  [getUserProfile.pending]: (state) => {
    state.isLoading.user = true;
  },
  [getUserProfile.fulfilled]: (state, { payload }) => {
    state.user.id = payload?.id;
    state.user.avatar = getAvatar(payload.avatar_urls);
    state.user.club = payload.meta?.club;
    state.user.membershipStatus = payload.meta?.membershipStatus;
    state.user.location = payload.meta?.userLocation;
    state.user.venue = payload.meta?.userMainVenue;
    state.user.phone = payload.meta?.userPhone;
    state.user.jobTitle = payload.meta?.userJobTitle;
    state.user.userNotes = payload.meta?.userNotes;
    state.user.role = getUserRoleFromArray(payload.roles);
    state.isLoading.user = false;
  },
  [getUserProfile.rejected]: (state) => {
    state.isLoading.user = false;
    localStorage.clear();
  },
};
