import React from "react";
import { useDispatch } from "react-redux";

import Icon from "components/site/icon";
import { removeNotifications } from "containers/app-store/slices";

const NotificationToast = ({ id, text }) => {
  const dispatch = useDispatch();

  const handleOnClose = () => {
    dispatch(removeNotifications(id));
  };

  return (
    <div className="notification-card--toast">
      <Icon className="notification-card--toast-icon" name="info" />
      <span>Notification</span>
      <span>{text}</span>
      <Icon className="notification-card--toast-close" name="close" onClick={handleOnClose} />
    </div>
  );
};

export default NotificationToast;
