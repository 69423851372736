import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "shared/js/api";
import { endpoint } from "shared/js/endpoints";

export const getAllClubs = createAsyncThunk("posts/getAllClubs", async ({ token }) => {
  let res = [];
  let page = 1;

  let currentRes = await request({ endpoint: `${endpoint.club}`, token, params: { per_page: 1000, page } });
  res.push(...currentRes);
  page = page + 1;

  while (currentRes.length === 1000) {
    currentRes = await request({ endpoint: `${endpoint.club}`, token, params: { per_page: 1000, page } });
    res.push(...currentRes);
    page = page + 1;
  }

  return res;
});

export const getAllClubsReducers = {
  [getAllClubs.pending]: (state) => {
    state.isLoading.homeClub = true;
  },
  [getAllClubs.fulfilled]: (state, { payload }) => {
    const list = payload?.map((club) => ({ label: Number(club.name), value: club.id }));
    state.allClubs = list;
    state.homeClub = list.find((club) => club.label === Number(state.user.club));
    state.isLoading.homeClub = false;
  },
  [getAllClubs.rejected]: (state) => {
    state.isLoading.homeClub = false;
  },
};
