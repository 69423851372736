import React, { lazy } from "react";
import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import LoadingPage from "pages/loading";
import { ROUTES } from "./routes";

// PAGES
const LoginPage = lazy(() => import("pages/login"));
const DashboardPage = lazy(() => import("pages/dashboard"));
const ProfilePage = lazy(() => import("pages/profile"));
const ReportsPage = lazy(() => import("pages/reports"));
// const Analytics = lazy(() => import("pages/analytics"));
const Filters = lazy(() => import("pages/filters"));
const MembersPage = lazy(() => import("pages/members"));
const MemberProfilePage = lazy(() => import("pages/member-profile"));
const SettingsPage = lazy(() => import("pages/settings"));
const NotFoundPage = lazy(() => import("pages/notfound"));
const ResetPasswordPage = lazy(() => import("pages/reset-password"));

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path={ROUTES.login} element={<LoginPage />} />
          <Route path={ROUTES.reset} element={<ResetPasswordPage />} />
          <Route path={ROUTES.dashboard} element={<DashboardPage />} />
          <Route path={ROUTES.profile} element={<ProfilePage />}>
            <Route path=":id" element={<ProfilePage />} />
          </Route>
          <Route path={ROUTES.reports} element={<ReportsPage />}>
            <Route path=":id" element={<ReportsPage />} />
          </Route>
          {/* <Route path={ROUTES.analytics} element={<Analytics />} /> */}
          <Route path={ROUTES.filters} element={<Filters />} />
          <Route path={ROUTES.members} element={<MembersPage />} />
          <Route path={ROUTES.member} element={<MemberProfilePage />}>
            <Route path=":id" element={<MemberProfilePage />} />
          </Route>
          <Route path={ROUTES.settings} element={<SettingsPage />}>
            <Route path=":id" element={<SettingsPage />} />
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
