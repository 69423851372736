import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import NotificationToast from "./NotificationToast";
import { removeNotifications } from "containers/app-store/slices";

const NotificationCard = () => {
  const dispatch = useDispatch();
  const site = useSelector((store) => store.main);
  const notificationRender = site.notifications.map((notification) => <NotificationToast key={notification.id} {...notification} />);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isEmpty(site.notifications)) {
        const notification = site.notifications[0];
        dispatch(removeNotifications(notification.id));
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [site.notifications]);

  if (isEmpty(site.notifications)) return <></>;

  return <div className="notification-card">{notificationRender}</div>;
};

export default NotificationCard;
